import request from '@/utils/request'

//          缴费记录控制器

// 水充值订单 分页
export function orderPage(query) {
  return request({
    url: '/wisdom/wisdom-order/page',
    method: 'get',
    params: query
  })
}

// 水充值订单 详情
export function orderInfo(query) {
  return request({
    url: '/wisdom/wisdom-order/detail',
    method: 'get',
    params: query
  })
}

//          订单退款控制器

// 订单退款 分页
export function orderRefundPage(query) {
  return request({
    url: '/wisdom/wisdom-order-refund/page',
    method: 'get',
    params: query
  })
}

// 订单退款 详情
export function orderRefundInfo(query) {
  return request({
    url: '/wisdom/wisdom-order-refund/detail',
    method: 'get',
    params: query
  })
}

// 订单退款 退款
export function orderRefundAdd(data) {
  return request({
    url: '/wisdom/wisdom-order-refund/add',
    method: 'post',
    data
  })
}

// 订单退款 取消退款
export function orderRefundEdit(data) {
  return request({
    url: '/wisdom/wisdom-order-refund/edit',
    method: 'post',
    data
  })
}

//          设备缴费

// 缴费列表 分页
export function orderRefundJfPage(query) {
  return request({
    url: '/wisdom/wisdom-order-refund/jf/page',
    method: 'get',
    params: query
  })
}

// 缴费列表 缴费
export function orderRefundJf(data) {
  return request({
    url: '/wisdom/wisdom-order-refund/jf',
    method: 'post',
    data
  })
}

// 缴费列表 退费
export function orderRefundTf(data) {
  return request({
    url: '/wisdom/wisdom-order-refund/tf',
    method: 'post',
    data
  })
}

// 订单流水 分页
export function orderFlowingPage(query) {
  return request({
    url: '/wisdom/wisdom-order-flowing/page',
    method: 'get',
    params: query
  })
}
